import { initializeApp } from "firebase/app";
import { getAuth, signOut } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBFduzE4ZV2U2sNnAPZGh0TwRqQ1l-hBVc",
  authDomain: "inv-teamrl.firebaseapp.com",
  databaseURL: "https://inv-teamrl-default-rtdb.firebaseio.com",
  projectId: "inv-teamrl",
  storageBucket: "inv-teamrl.appspot.com",
  messagingSenderId: "1000630838800",
  appId: "1:1000630838800:web:13c9e7df2941c1681a0c95",
  measurementId: "G-D44DS6XJJM"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const functions = getFunctions(app, 'europe-west2');
export const messaging = getMessaging(app);

export const fireBaseSignOut = () => {
  signOut(auth);
};
