import { useState } from 'react';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';

import style from './editor.module.scss';

const TextEditor = (props) => {
  const [textArea, setTextArea] = useState('');

  const submitData = () => {
    props.handleEditorResponse(textArea);
    setTextArea('');
  };

  const handleEditorChange = ({ text }) => {
    setTextArea(text);
  };

  return (
    <div className={style['reply']}>
      <h5>{props.title}</h5>

      <MdEditor
        className={style['textarea']}
        style={{ height: '300px' }}
        value={textArea}
        canView={{menu: true, md: false, html: false, fullScreen: false, hideMenu: false}}
        view={{menu: true, md: true, html: false}}
        renderHTML={text => null}
        onChange={handleEditorChange}
      />

      <span className="outline" role="button" onClick={submitData}>
        Submit
      </span>
    </div>
  );
}

export default TextEditor;
