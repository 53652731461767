const request = (options, type) => {
  const headers = new Headers();
  headers.append(
    'Content-Type', 'application/json'
  );

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options).then(response =>
    response.json().then(json => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};


export function getLiveScore(payload) {
  return request({
    url: `https://faas-lon1-917a94a7.doserverless.co/api/v1/web/fn-5b61a14c-a1bf-44fd-8aa8-acccf5e8fe07/teamrlFunctions/liveScores`,
    method: 'POST',
    body: JSON.stringify(payload)
  });
}
