import { useContext, useState } from 'react';
import { httpsCallable } from "firebase/functions";
import { functions } from 'Utils/Firebase';
import { UserContext } from 'Utils/UserContext';
import { ConvertTimestamp } from 'Utils/ConvertTimestamp';
import { CheckSquare, XSquare } from 'react-feather';

const UserSubscriptions = () => {
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const getSubPortalLink = async () => {
    setIsLoading(true);
    const addMessage = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
    const { data } = await addMessage({
      returnUrl: window.location.origin
    });
    window.location.assign(data.url);
  };

  return (
    <div>
      {user.profile.status
        ?
          <div>
            <table>
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Length</th>
                  <th scope="col">Status</th>
                  <th scope="col">Ends</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>TeamRL Subscription</td>
                  <td>{user.profile.items[0].plan.interval}</td>
                  <td>
                    {user.profile.status === 'active'
                      ? <CheckSquare size={22} color="green" />
                      : <XSquare size={22} color="red" />
                    }
                  </td>
                  <td>{ConvertTimestamp(user.profile.current_period_end.seconds)}</td>
                </tr>
              </tbody>
            </table>
            <span role="button" aria-busy={isLoading} onClick={() => getSubPortalLink()}>
              {isLoading
                ? 'Loading...'
                : 'Manage your subscription'
              }
            </span>
          </div>
        : <p>No Active subscription</p>
      }
    </div>
  );
}

export default UserSubscriptions;
