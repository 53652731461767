import { app } from 'Utils/Firebase';
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where
} from "firebase/firestore";

const db = getFirestore(app);

export const getUserFromDB = async (uid) => {
  // Get user details from the database
  const user = await getDoc(doc(db, 'users', uid));

  let UserData = [];

  // Check to see if there's an active subscription
  const subCollection = collection(db, `users/${uid}/subscriptions`);
  const subQuery = query(subCollection, where("status", "in", ['trialing', 'active']));
  const checkActiveSub = await getDocs(subQuery);

  // Return the user data
  try {
    const subDetails = checkActiveSub.docs[0];
    return UserData = {
      ...subDetails.data(), ...user.data()
    }
  } catch {
    return UserData = {
      ...user.data()
    }
  }
};
