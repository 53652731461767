const AupPage = () => {
  return (
    <article>
      <header><strong>Acceptable Usage Policy</strong></header>
      <p>
        By registering and participating on TeamRL you agree to the following code of conduct.
        If you are unable to agree you have the right not to participate in discussions at any time.
      </p>
      <p>
        While the owner and moderators of this website will attempt to remove or edit any generally
        objectionable material as quickly as possible where acceptable, it is impossible to review
        every message. Therefore you acknowledge that all posts made to this website express the
        views and opinions of the author and not the owner or moderators
        (except for posts by these people) and they will not be held liable.
      </p>
      <ol>
        <li>You agree not to post any abusive, obscene, vulgar, slanderous, hateful, threatening,
          sexually-orientated material or any other material that may violate any applicable laws.
        Doing any of these may lead to you being temporarily or permanently banned from TeamRL</li>
      </ol>
    </article>
  );
}

export default AupPage;
