import { useState, useContext } from 'react';
import toast from 'react-hot-toast';
import { app } from 'Utils/Firebase';
import { UserContext } from 'Utils/UserContext';
import { createCheckoutSession, getStripePayments } from "@stripe/firestore-stripe-payments";

const SubscribePage = () => {
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const payments = getStripePayments(app, {
    productsCollection: "subscriptions",
    customersCollection: "users",
  });

  const createSubscription = async (subType) => {
    setIsLoading(true);
    toast.success('Redirecting you to the payment screen...');
    const session = await createCheckoutSession(payments, {
      price: subType,
      allow_promotion_codes: true,
    });
    window.location.href = session.url;
  };

  return (
    <div className="container">
      <article>
        <header><strong>TeamRL Subscription</strong></header>
        <p>
          Your season ticket to exclusive content. In addition to helping the website
          you'll also gain some new features, with more being added all the time.
        </p>
        <h5>Features</h5>
        <details>
          <summary><strong>No Advertisements</strong></summary>
          <p>All advertising will be removed while you're an active subscriber.</p>
        </details>
        <details>
          <summary><strong>Create unlimited discussions</strong></summary>
          <p>Have a topic you want to share with everyone? As a subscriber you'll be able to create unlimited
            discussions where other users will be able to reply. Subscriber created discussions are
            marked with [Sub] in the title.</p>
        </details>
        <details>
          <summary><strong>Username highlighted in discussions &amp; live chat</strong></summary>
          <p>Your username will be marked as a subscriber in all discussions.</p>
        </details>
        <details>
          <summary>Access additional quick replies in chat (Coming soon)</summary>
          <p>Show your support with subscriber only quick replies which include additional animations
            and messages.</p>
        </details>
        <details>
          <summary>Advanced notifications (Coming soon)</summary>
        </details>
        <footer>
          <h6>Subscribe Today!</h6>
          {user.auth
            ?
            <div>
              <button
                disabled={isLoading}
                aria-busy={isLoading}
                onClick={() => createSubscription('price_1MjKxIJGclKqVLCRyTaiIg7D')}>
                £19.99 / Yearly - Save over 17%!
              </button>
              <button
                className="outline"
                disabled={isLoading}
                aria-busy={isLoading}
                onClick={() => createSubscription('price_1MjKx7JGclKqVLCRJW7mWIds')}>
                £1.99 / Monthly
              </button>
            </div>
            : <p>Please login to purchase a subscription</p>
          }
        </footer>
      </article>
    </div>
  );
}

export default SubscribePage;
