import { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from 'Utils/UserContext';
import { fireBaseSignOut } from 'Utils/Firebase';
import toast from 'react-hot-toast';

import style from './user.module.scss';

import {
  UserLoginModal
} from 'Components';

const MenuPanel = () => {
  const [showAuth, setShowAuth] = useState(false);
  const [showLinks, setShowLinks] = useState(false);
  const { user, logout } = useContext(UserContext);

  const toggleModalWindow = () => {
    setShowAuth(!showAuth);
  };

  const signUserOut = () => {
    fireBaseSignOut();
    logout();
    toast.success(`Signed out`);
  };

  useEffect(() => {}, [user]);

  return !user ? <div className="container" aria-busy="true"></div> : (
    <div>
      <div className={style['content']}>
        {user.auth
          ?
            <div className={style['account']}>
              <div>
                Signed in as: <strong>{user.profile.displayName}</strong>
                {user.profile.mod &&
                  <>
                  <div className={style['dropdown']}>
                    <span
                    onClick={() => setShowLinks(!showLinks)}
                    >
                      Mod Links
                    </span>
                    <ul className={`${style['dropdown-links']} ${showLinks ? style['active'] : ''}`}>
                      <li className={style['dropdown-link']}>
                        <Link to="/matchcreate">
                          Create new match
                        </Link>
                      </li>
                      <li className={style['dropdown-link']}>
                      <Link to="/newarticle">
                        Create new article
                      </Link>
                      </li>
                    </ul>
                  </div>
                  </>
                }
              </div>
              <div>
                <Link className={`${style['action-link']} ${style['action-link--highlight']}`} to="/subscribe">Subscribe!</Link>
                <Link className={style['action-link']} to="/user">Account</Link>
                <span className={style['action-link']} onClick={() => signUserOut()}>
                  Sign out
                </span>
              </div>
            </div>
          :
          <div className={style['btn-group']}>
            <span className={style['action-link']} onClick={() => setShowAuth(showAuth => !showAuth)}>
              Login
            </span>
            <Link className={style['action-link']} to="/register">
              Register
            </Link>
          </div>
        }
      </div>
      { showAuth ? <UserLoginModal handleModalView={toggleModalWindow} /> : null }
    </div>
);
}

export default MenuPanel;
