import { useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { UserContext } from 'Utils/UserContext';
import { deleteFixture } from 'API/deleteFixture';
import toast from 'react-hot-toast';
import style from './style.module.scss';

import {
  LiveChat,
  LiveScore
} from 'Components';

const ModPanel = (props) => {
  const navigate = useNavigate();

  const removeCurrentFixture = (id) => {
    deleteFixture(props.fixtureID).then(() => {
      toast.success('Removed Fixture');
      navigate(`/`);
    }).catch(error => {
      console.log(error);
    });
  };

  return (
    <div className={style['mod']}>
      <span
        className={`${style['btn']} ${style['btn-danger']}`}
        onClick={(e) => removeCurrentFixture()}
      >
        Delete Fixture
      </span>
    </div>
  );
}

const FixturePage = () => {
  let { fixture } = useParams();
  const { user } = useContext(UserContext);

  return (
    <div className="container">
      {user.profile.mod &&
        <ModPanel fixtureID={fixture} />
      }
      <div className={style['fixture']}>
        <LiveScore team={fixture} />
        <LiveChat chatID={fixture} />
      </div>
    </div>
  );
}

export default FixturePage;
