import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';

import {
  Toaster
} from 'react-hot-toast';

import {
  UserProvider
} from 'Utils/UserContext';

import {
  HomePage
} from 'Pages';

import {
  SiteHeader,
  SiteFooter
} from 'Components';

const App = () => {
  return (
    <BrowserRouter>
      <UserProvider>
        <Toaster />
        <SiteHeader />
        <Routes>
          <Route path='*' element={<HomePage />} />
        </Routes>
        <SiteFooter />
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
