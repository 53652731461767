import { useState, useContext } from 'react';
import { UserContext } from 'Utils/UserContext';
import { DateFormat } from 'Utils/DateFormat';
import toast from 'react-hot-toast';
import ReactMarkdown from 'react-markdown';
import { Pagination } from 'Components';
import { deleteSingleDiscussion } from 'API/handleDiscussionReplies';
import { XCircle } from 'react-feather';

import style from './thread.module.scss';

const ThreadReplies = (props) => {
  const { user } = useContext(UserContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = props.replies.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  const deleteItem = (post) => {
    deleteSingleDiscussion(props.threadID, post).then(response => {
      toast.success('Post deleted');
    }).catch(error => {
      console.log(error);
    });
  };

  return (
    <div>
      {currentPosts.map((post, index) => (
        <div className={style['reply']} key={index}>
          <div className={style['reply-header']}>
            <div>
              By:
              <span className={`${style['user']} ${post.sub ? style['is-sub'] : null}`}>{post.user}</span>
              » {DateFormat(post.date)}
            </div>
            {user.profile.mod &&
              <div className={style['tools']}>
                <span className={`${style['tools-btn']} ${style['tools-btn--delete']}`} onClick={() => deleteItem(post)}>
                  <XCircle size={18} />Delete
                </span>
              </div>
            }
          </div>
          <div className={style['reply-content']}>
            <ReactMarkdown>
              {post.message}
            </ReactMarkdown>
          </div>
        </div>
      ))}

      <Pagination
        postsPerPage={postsPerPage}
        currentPage={currentPage}
        totalPosts={props.replies.length}
        paginate={paginate}
      />
    </div>
  );
}

export default ThreadReplies;
