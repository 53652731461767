import { useContext, useState } from 'react';
import { sendEmailVerification } from 'firebase/auth';
import { UserContext } from 'Utils/UserContext';
import { UserSubscriptions, UserNotifications } from 'Components';
import toast from 'react-hot-toast';
import { CheckSquare } from 'react-feather';

import style from './user.module.scss';

const UserPage = () => {
  const { user } = useContext(UserContext);
  const [disabled, setDisabled] = useState(false);

  const verifyEmail = () => {
    setDisabled(true);
    sendEmailVerification(user.account.auth.currentUser)
      .then(() => {
        toast.success('Verification email sent.');
      });
  };

  if (user.auth) return (
    <div className="container grid">
      <article>
        <header><strong>Subscription</strong></header>
        <UserSubscriptions />
      </article>

      <article>
        <header><strong>Account</strong></header>
        <table>
          <tbody>
            <tr>
              <td><strong>Display Name</strong></td>
              <td className={style['align-right']}>{user.profile.displayName}</td>
            </tr>
            <tr>
              <td><strong>Email Address</strong></td>
              <td className={style['align-right']}>{user.profile.email}</td>
            </tr>
            <tr>
              <td>
                <strong>Verified</strong>
                {!user.account.emailVerified &&
                  <p>In order to use TeamRL your email account needs to be verified.</p>
                }
              </td>
              <td className={style['align-right']}>
                {user.account.emailVerified
                  ?
                  <div>
                    <CheckSquare size={22} color="green" />
                  </div>
                  :
                  <button disabled={disabled} className={`${style['button']} ${style['button-verify']}`} onClick={e => verifyEmail()}>
                    Verify Email Address
                  </button>
                }
              </td>
            </tr>
          </tbody>
        </table>
      </article>

      <article>
        <header><strong>Notifications</strong></header>
        <p>
        TeamRL offer web and push notifications to supporting devices. These
        notifications will only ever include live score updates or discussion
        updates if you're subscribed to a topic.
        </p>
        {user.profile.notify &&
          <div>
            Notifications for this account are <UserNotifications />
          </div>
        }
      </article>
    </div>
  );
}

export default UserPage;
