export const DateFormat = (timestamp, expires) => {
  const date = new Date(timestamp);

  if (expires) {
    date.setDate(date.getDate() + 14);
  }

  const formatDate = date.toLocaleDateString("en-GB");
  const formatTime = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: true });

  if (expires) {
    return `${formatDate}`;
  } else {
    return `${formatDate} ${formatTime}`;
  }
};

export const DateFormatNoTime = (timestamp) => {
  const date = new Date(timestamp);
  const formatDate = date.toLocaleDateString("en-GB");
  return `${formatDate}`;
};
