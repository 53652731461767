import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { UserContext } from 'Utils/UserContext';
import { createNewsArticle } from 'API/createNews';

import { TextEditor } from 'Components';

const CreateArticle = () => {
  const [title, setTitle] = useState('');
  const { user } = useContext(UserContext);

  const getEditorResponse = (data) => {
    submitArticle(data);
  };

  const submitArticle = (data) => {
    const slug = title.replace(/\s+/g, '-').toLowerCase();
    const replyData = {
      title: title,
      content: data,
      user: user.profile.displayName,
      published: Date.now()
    };

    createNewsArticle(replyData, slug).then(() => {
      toast.success('Added new article');
    }).catch(error => {
      console.log(error);
    });
  };

  return (user.profile.editor)
    ?
    <div className="container">
      <h5>Create Article</h5>
      <label>Discussion title</label>
      <input type="text" onChange={(e) => setTitle(e.target.value)} />
      <TextEditor
        title="Discussion content"
        handleEditorResponse={getEditorResponse}
      />
    </div>
    : (
      <div>
        Sorry you're not authorised to create new article.
      </div>
    );
}

export default CreateArticle;
