import style from './paging.module.scss'

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className={style['paging']}>
      <ul className={style['list']}>
        {pageNumbers.map(number => (
          <li key={number} className={style['item']}>
            <span
              onClick={() => paginate(number)}
              className={`${style['page']} ${currentPage === number ? style['active'] : ''}`}
            >
              {number}
            </span>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
