import style from './style.module.scss';

import {
  FixtureList,
  DiscussionsList,
  NewsList,
  NewsBanner
} from 'Components';

const OverviewPage = () => {
  return (
    <div className={style['overview']}>
      <NewsBanner type="homepage" />
      <div className={style['grid']}>
        <FixtureList />
        <DiscussionsList limit={6} title="Community" showAll={true} />
        <NewsList />
      </div>
    </div>
  );
}

export default OverviewPage;
