const AboutPage = () => {
  return (
    <div className="container">
      <article>
        <header><strong>About TeamRL</strong></header>
        <div>
          <p>Welcome to TeamRL, your go-to online destination for all things related to Rugby League!</p>

          <p>We are a team of passionate and dedicated individuals who are committed to bringing you the latest news, live chat, and engaging discussions about the world of Rugby League. Our mission is to provide a platform where fans from around the world can come together and share their thoughts, opinions, and insights about the sport they love.</p>

          <p>But we're not just a news site - we also offer a dynamic and engaging community for fans to connect and chat in real-time. Our live chat feature allows you to join in on the conversation as matches happen, share your thoughts, and get instant reactions from other passionate fans.</p>

          <p>At TeamRL, we pride ourselves on being a reliable and trustworthy source of information for all things Rugby League. Our team of writers and contributors are experienced and knowledgeable about the sport, and we work tirelessly to ensure that our content is always up-to-date and informative.</p>

          <p>So whether you're looking for the latest news and analysis, want to join in on the live chat during games, or simply want to connect with other Rugby League fans from around the world, TeamRL is the place for you. Join us today and become a part of our thriving community of Rugby League enthusiasts!</p>
        </div>
        <h5>FAQs</h5>
        <details>
          <summary><strong>Contact us</strong></summary>
          <p>Please email contact@teamrl.co.uk</p>
        </details>
        <details>
          <summary><strong>Advertise on TeamRL</strong></summary>
          <p>For all enquiries please get in touch. We offer a wide variety of options for advertising.</p>
        </details>
        <details>
          <summary><strong>How do I create a discussion?</strong></summary>
          <p>TeamRL isn't an ordinary forum, instead pre-defined weekly discussions are created
            based on news or other current affairs.</p>
          <p>The exception to this is if you're a subscriber, then you're able to create new
            discussions which will be marked with [Sub] before the title.</p>
        </details>
        <details>
          <summary><strong>What competitions do you cover?</strong></summary>
          <p>TeamRL is currently under a soft launch phase and as such live matches and 'tagged' team
            discussions will only cover Super League fixtures. We aim to implement additional leagues
            in the near future.</p>
        </details>
        <details>
          <summary><strong>Report a message</strong></summary>
          <p>While we aim to remove all content that goes against our AUP sometimes they can get
            through. If you need to report a message please get in touch with us: contact@teamrl.co.uk</p>
        </details>
        <details>
          <summary><strong>TeamRL Network</strong></summary>
          <p>In addition to running TeamRL as our main 'hub' we also operate additional websites.</p>
          <ul>
            <li><a href="https://casfans.co.uk" rel="noreferrer" target="_blank">Casfans</a> • Dedicated community for Castleford Tiger fans.</li>
          </ul>
          <p>We're aiming to grow our network with additional websites and communities in the future.</p>
        </details>
      </article>
    </div>
  );
}

export default AboutPage;
