import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'Utils/UserContext';
import { createMatch } from 'API/createMatch';
import toast from 'react-hot-toast';
import TeamList from 'API/teams.json';

const CreateMatchPage = () => {
  const [homeTeam, setHomeTeam] = useState('');
  const [awayTeam, setAwayTeam] = useState('');
  const [fixtureDate, setFixtureDate] = useState('');
  const [fixtureType, setFixtureType] = useState('super-league');
  const [specialFixture, setSpecialFixture] = useState(false);
  const [specialLocation, setSpecialLocation] = useState('');
  const [scoreURL, setScoreURL] = useState('');
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const submitMatch = () => {
    const matchData = {
      date: fixtureDate,
      slug: `${homeTeam}-${awayTeam}`,
      fixtureType: fixtureType,
      specialFixture: specialFixture,
      specialLocation: specialLocation,
      scoreProvider: scoreURL,
      created: Date.now()
    };

    createMatch(matchData).then(response => {
      toast.success('Added new match');
      navigate(`/${fixtureType}/${matchData.slug}`);
    }).catch(error => {
      console.log(error);
    });
  };

  if (user.profile.mod) return (
    <div className="container">
      <article>
        <header>
          <strong>Create new match</strong>
        </header>

        <div className="grid">
          <div>
            <label>Fixture Type</label>
            <select onChange={(e) => setFixtureType(e.target.value)}>
              <option value="super-league">Super League</option>
              <option value="championship">Championship</option>
              <option value="challenge-cup">Challenge Cup</option>
            </select>
          </div>
          <div>
            <label>Fixture Date</label>
            <input
              type="datetime-local"
              id="birthdaytime"
              name="birthdaytime"
              onChange={(e) => setFixtureDate(e.target.value)}
            />
          </div>
        </div>

        {fixtureType
          ?
          <div className="grid">
            <div>
              <label>Home team</label>
              <select onChange={(e) => setHomeTeam(e.target.value)}>
                <option>Please select..</option>
                {TeamList[fixtureType].map((team, index) => (
                  <option key={index} value={team.slug}>{team.name}</option>
                ))}
              </select>
            </div>
            <div>
              <label>Away team</label>
              <select onChange={(e) => setAwayTeam(e.target.value)}>
                <option>Please select..</option>
                {TeamList[fixtureType].map((team, index) => (
                  <option key={index} value={team.slug}>{team.name}</option>
                ))}
              </select>
            </div>
          </div>
          : null
        }

        <label>Score Provider</label>
        <p className="help">URL to get the live score from. This is set to get the current score from Sky Sports</p>
        <input
          type="input"
          id="scoreurl"
          name="scoreurl"
          placeholder="https://www.skysports.com/rugby-league/<slug>/<id>"
          onChange={(e) => setScoreURL(e.target.value)}
        />

        <label>Special Fixture</label>
        <p className="help">Changes the location from the default home ground to a specified location</p>
        <input type="checkbox" onChange={(e) => setSpecialFixture(e.target.checked)} />

        {specialFixture &&
          <div>
            <label>Location</label>
            <input type="text" onChange={(e) => setSpecialLocation(e.target.value)} />
          </div>
        }

        <footer>
          <span role="button" className="outline" onClick={e => submitMatch()}>
            Create
          </span>
        </footer>
      </article>
    </div>
  );
}

export default CreateMatchPage;
