import { app } from 'Utils/Firebase';
import {
  getFirestore,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove
} from "firebase/firestore";

const db = getFirestore(app);

export const replySingleDiscussion = async (id, data) => {
  const thread = doc(db, 'discussions', id);
  await updateDoc(thread, {
    replies: arrayUnion(data)
  });

  return 'Added reply';
};

// Delete reply
export const deleteSingleDiscussion = async (id, reply) => {
  try {
    const thread = doc(db, 'discussions', id);
    await updateDoc(thread, {
      replies: arrayRemove(reply)
    });
  } catch (e) {
    return e.message;
  }
};
