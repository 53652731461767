import { useState, useEffect } from 'react';
import { DateFormat } from 'Utils/DateFormat';
import { getSingleMatch } from 'API/getSingleMatch';
import { getLiveScore } from 'API/getLiveScore';
import TeamList from 'API/teams.json';
import style from './score.module.scss';

const LiveScore = (props) => {
  const [matchLoading, setMatchLoading] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [scoreURL, setScoreURL] = useState('');
  const [match, setMatch] = useState([]);
  const [scores, setScores] = useState([]);
  const [teamList, setTeamList] = useState([]);

  const getTeamData = (type) => {
    const { team } = props;
    const teams = team.split('-');

    teams.forEach((item) => {
      let teamObj = TeamList[type].find(el => el.slug === item);
      setTeamList(teams => [...teams, teamObj]);
    });
  }

  const loadLiveScore = () => {
    const payload = {
      url: scoreURL
    };

    getLiveScore(payload).then(response => {
      setLoading(false);
      setScores(response);
    }).catch(error => {
      setLoading(false);
      console.log(error);
    });
  };

  const formatFixtureType = (type) => {
    const formattedType = type.replace(/-/g, " ");
    return formattedType;
  };

  useEffect(() => {
    getSingleMatch(props.team).then(response => {
      setMatchLoading(false);
      setMatch(response);
      setScoreURL(response.scoreProvider);
      getTeamData(response.fixtureType);
    }).catch(error => {
      setMatchLoading(false);
      console.log(error);
    });

    if (scoreURL) {
      loadLiveScore();
      const interval = setInterval(() => {
        loadLiveScore()
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [props.id, scoreURL]);

  if (!match) return (
    <div>No match found</div>
  );

  return matchLoading ? 'Loading...' : (
    <div className={style['panel']}>
      <div className={style['details']}>
        <p className={style['details-type']}>{formatFixtureType(match.fixtureType)}</p>
        <p className={style['details-team']}>{teamList[0].name} v {teamList[1].name}</p>
        <p>{DateFormat(match.date)}</p>
        <p>
          {match.specialFixture
            ? match.specialLocation
            : teamList[0].location
          }
        </p>
      </div>
      <ul className={style['board']} aria-busy={isLoading}>
        {scores.map((item, index) => (
          <li key={index} className={style['board-item']}>
            <div className={style['team']}>
              <span>{item.teamName}</span>
              <span className={style['board-score']}>{item.teamScore}</span>
            </div>
            <p className={style['scorers']}>{item.teamScorers}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default LiveScore;
