import { useState, useEffect } from 'react';
import { getBanner } from 'API/getNewsBanner';

import style from './style.module.scss';

const NewsBanner = (props) => {
  const [banner, setBanner] = useState([]);
  const { type } = props;

  useEffect(() => {
    getBanner(type).then(response => {
      setBanner(response);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  return (
    <div className={style['banner']}>
      <div className={style['title']}>
        {banner.title}
      </div>
      <div className={style['message']}>
        {banner.message}
      </div>
    </div>
  );
}

export default NewsBanner;
