import { useState, createContext } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    account: [],
    profile: [],
    auth: false
  });

  const login = (account, profile) => {
    setUser((user) => ({
      account: account,
      profile: profile,
      auth: true,
    }));
  };

  const logout = () => {
    setUser((user) => ({
      ...user,
      account: [],
      profile: [],
      auth: false,
    }));
  };

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
}
