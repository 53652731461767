import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from 'Utils/UserContext';

import style from './ad.module.scss';

const AdPanel = (props) => {
  const { user } = useContext(UserContext);

  const checkSubStatus = () => {
    if (user.profile.status === 'active') {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const adID = document.getElementById('cfad');
    const script = document.createElement("script");
    script.src = 'https://udbaa.com/bnr.php?section=General&pub=344337&format=300x250&ga=g&bg=1';

    adID.appendChild(script);

    return () => {
      adID.removeChild(script);
    }
  }, [])

  if (checkSubStatus) return (
    <div>
      <div id="cfad"></div>
      <span className={style['remove']}>
        Remove adverts with our <Link to="/subscribe">subscription</Link>
      </span>
    </div>
  );
}

export default AdPanel;
