import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from 'Utils/GetAuth';
import { UserContext } from 'Utils/UserContext';
import { app } from 'Utils/Firebase';
import SubmitChatForm from './SubmitChatForm';
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { deleteSingleChatItem } from 'API/handleChatMessages';
import toast from 'react-hot-toast';

import { XCircle, Slash } from 'react-feather';

import style from './chat.module.scss';

const LiveChat = (props) => {
  let { fixture } = useParams();
  const { user } = useContext(UserContext);
  const [chatLog, setChatLog] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const auth = useAuth();
  const db = getFirestore(app);

  const deleteChatItem = (key) => {
    toast(`Removing chat item....`);
    deleteSingleChatItem(fixture, key).then(response => {
      toast.success(`Chat item: ${key} has been removed`);
    }).catch(error => {
      console.log(error);
    });
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, 'chat', props.chatID), (doc) => {
      if (doc.data()) {
        setIsLoading(false);
        setChatLog(doc.data());
      }
    });
    return () => unsubscribe();
  }, [db, props.chatID]);

  return isLoading ? 'Loading...' : (
    <div>
      <div className={style['chatbox']}>
        {chatLog &&
          Object.entries(chatLog).sort(([,{created:a}], [,{created:b}])=> b-a).map(([key, val]) =>
            <div key={key} data-id={key} data-action={val.action} className={style['chat-item']}>
              {user.profile.mod &&
                <div className={style['mod']}>
                  <span className={`${style['mod-btn']} ${style['mod-btn-warn']}`} title="Delete" onClick={() => deleteChatItem(key)}>
                    <XCircle size={18} color="black" />
                  </span>
                  <span className={style['mod-btn']} title="Ban"><Slash size={18} color="white" /></span>
                  <span className={style['uid']}>UID: {val.uid}</span>
                </div>
              }
              <span className={`${style['chat-user']} ${val.sub ? style['sub'] : null}`}>{val.user}: </span>
              <p className={style['chat-message']}>
                {val.message}
              </p>
            </div>
          )
        }
      </div>
      {auth
        ? <SubmitChatForm pageID={fixture} />
        : 'Sign in or register to chat live...'
      }
    </div>
  );
}

export default LiveChat;
