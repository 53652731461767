import { app } from 'Utils/Firebase';
import { getFirestore, collection, getDocs, query, limit, orderBy } from 'firebase/firestore/lite';

const db = getFirestore(app);

export const listDiscussions = async (results) => {
  const listRef = collection(db, "discussions");
  const q = query(listRef, orderBy("created", "desc"), limit(results));
  const getCollection = await getDocs(q);
  const list = getCollection.docs.map((doc) => ({id: doc.id, ...doc.data()}))
  return list;
};
