import { useState, useContext, Fragment, useEffect } from 'react';
import { addChatMessage } from 'API/addChatMessage';
import { UserContext } from 'Utils/UserContext';
import toast from 'react-hot-toast';

import style from './form.module.scss';

const SubmitChatForm = (props) => {
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [actionsDisabled, setDisabledActions] = useState(false);
  const [actionList] = useState(['try', 'no try', 'penalty', 'offside', 'forward', '40-20']);

  const checkSubStatus = () => {
    if (user.profile.status === 'active') {
      return true;
    }
    return false;
  };

  const submitMessage = (action) => {
    setIsLoading(true);
    let reply = '';
    if (action) {
      reply = action;
      setDisabledActions(true);
    } else {
      reply = message;
    }

    const messageData = {
      [Date.now()]: {
        "user": user.profile.displayName,
        "uid": user.account.uid,
        "message": reply,
        "created": Date.now(),
        "sub": checkSubStatus(),
        "action": action
      }
    };

    if (!reply || actionsDisabled) {
      if (actionsDisabled) {
        setIsLoading(false);
        toast.error('Please wait before using quick reply again');
      } else {
        setIsLoading(false);
        toast.error('No reply entered');
      }

    } else {
      addChatMessage(props.pageID, messageData).then(response => {
        reply = '';
        setMessage('');
        toast.success('Added message');
        setIsLoading(false);

        if (action) {
          setTimeout (function() {
            setDisabledActions(false);
          }, 10000);
        }
      }).catch(error => {
        reply = '';
        setMessage('');
        setIsLoading(false);
        console.log(error);
      });
    }
  };

  useEffect(() => {
  }, [message]);

  if (user.profile.banned) return (
    <div>Sorry this account is unable to use live chat.</div>
  );

  return (
    <Fragment>
      <ul className={style['actions']}>
        {actionList.map((action, index) =>
          <li key={index} className={`${style['action-item']} ${style['action-btn']} ${actionsDisabled ? style['action-btn-disabled'] : ''}`} onClick={(e) => submitMessage(action)}>
            {action}
          </li>
        )}
      </ul>
      <div className={style['reply']}>
        <input
          type="text"
          placeholder="Send a message...."
          value={message}
          className={style['input']}
          onChange={(e) => setMessage(e.target.value)}
        />
        <span onClick={() => submitMessage(null)} className={style['btn']} aria-busy={isLoading}>
          Send
        </span>
      </div>
    </Fragment>
  );
}

export default SubmitChatForm;
