import { useState, useContext } from 'react';
import { auth } from 'Utils/Firebase';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { UserContext } from 'Utils/UserContext';
import toast from 'react-hot-toast';

import style from './login.module.scss';

const UserLoginModal = (props) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useContext(UserContext);

  const signInUser = () => {
    setIsLoading(true);
    signInWithEmailAndPassword(auth, emailAddress, password)
    .then((account) => {
      setIsLoading(false);
      login(account.user, []);
      props.handleModalView();
      toast.success(`Signed in as ${account.user.email}`);
    })
    .catch((error) => {
      setIsLoading(false);
      toast.error('Incorrect details - try again');
    });
  };

  const closeModalWindow = () => {
    props.handleModalView();
  };

  const sendForgotPassword = () => {
    if (!emailAddress) {
      toast.error('Enter your email address');
    } else {
      sendPasswordResetEmail(auth, emailAddress)
        .then(() => {
          closeModalWindow();
          toast.success('Password reset request sent - check your email');
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  }

  return (
    <dialog open>
      <article>
        <header><strong>Login to your TeamRL account</strong></header>
        <label>Email Address</label>
        <input
          type="email"
          required
          placeholder="email@yourdomain.com"
          onChange={(e) => setEmailAddress(e.target.value)}
        />
        <label>Password</label>
        <input
          type="password"
          required
          placeholder="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <span className={style['reset']} onClick={e => sendForgotPassword()}>
          Forgot password?
        </span>
        <footer>
          <button onClick={e => signInUser()} aria-busy={isLoading ? 'true' : 'false'}>
            {isLoading
              ? 'Logging in...'
              : ' Login'
            }
          </button>
          <button className="secondary" onClick={closeModalWindow}>
            Cancel
          </button>
        </footer>
      </article>
    </dialog>
  );
}

export default UserLoginModal;
