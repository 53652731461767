import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DateFormatNoTime } from 'Utils/DateFormat';
import { ListNews } from 'API/getNews';

import style from './news.module.scss';

const NewsList = () => {
  const [newsList, setNewsList] = useState([]);

  useEffect(() => {
    ListNews().then(response => {
      setNewsList(response);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  return (
    <div>
      <div className="panel">
        <div className="panel-header">Latest Articles</div>
        <div className={style['news-list']}>
          {newsList.map((item, index) => (
            <div className={style['item']} key={index}>
              <Link to={`/news/${item.id}`} className={style['link']}>
                {item.title}
              </Link>
              <span className={style['date']}>{DateFormatNoTime(item.published)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default NewsList;
