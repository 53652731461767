import { app } from 'Utils/Firebase';
import { getFirestore, setDoc, doc } from "firebase/firestore/lite";

const db = getFirestore(app);

export const createMatch = async (data) => {
  // const matchRef = await addDoc(collection(db, "matches", data.slug), data);
  await setDoc(doc(db, "matches", data.slug), data);
  await setDoc(doc(db, "chat", data.slug), {});

  return true;
};
