import { app } from 'Utils/Firebase';
import {
  getFirestore,
  collection,
  getDocs,
  query,
  orderBy
} from 'firebase/firestore/lite';

const db = getFirestore(app);

export const getAllMatches = async () => {
  const listRef = collection(db, "matches");
  const q = query(listRef, orderBy("date"));
  const getCollection = await getDocs(q);
  const list = getCollection.docs.map((doc) => ({id: doc.id, ...doc.data()}))
  return list;
};
