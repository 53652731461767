import { app } from 'Utils/Firebase';
import { getFirestore, doc, getDoc } from 'firebase/firestore/lite';

const db = getFirestore(app);

export const getNewsItem = async (id) => {
  const content = await getDoc(doc(db, 'news', id));
  if (content.exists()) {
    return content.data();
  } else {
    console.log("Content doesn't exist");
  }
};
