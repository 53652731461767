import { useState, useContext } from 'react';
import { app, auth } from 'Utils/Firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import { UserContext } from 'Utils/UserContext';

import style from './register.module.scss';

const RegisterPage = (props) => {
  const [displayName, setDisplayName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const { login } = useContext(UserContext);
  const navigate = useNavigate();
  const db = getFirestore(app);

  const checkIfDisplayNameValid = () => {
    const content = {
    	"data": {
    		"displayName": displayName.replace(/\s+/g, '-').toLowerCase()
    	}
    };

    const options = {
      method: 'POST',
      body: JSON.stringify(content),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }

    return fetch('https://europe-west2-inv-teamrl.cloudfunctions.net/createNewUser', options).then(response =>
      response.json().then(json => {
        if (!response.ok) {
          return Promise.reject(json);
        }
        return json;
      })
    );
  };

  const registerNewUser = async () => {
    setIsLoading(true);

    await checkIfDisplayNameValid().then(response => {
      if (response.result) {
        setIsLoading(false);
        toast.error('Display name is already taken - please choose another.');
      } else {
        createUserWithEmailAndPassword(auth, emailAddress, password)
        .then((userCredential) => {
          const user = userCredential.user;

          const profileData = {
            'displayName': displayName,
            'displayNameSlug': displayName.replace(/\s+/g, '-').toLowerCase(),
            'mod': false,
            'banned': false
          };

          login(user, profileData);
          setDoc(doc(db, "users", user.uid), profileData);
          toast.success('Account registered');
          setIsLoading(false);
          navigate("/user");
        })
        .catch((error) => {
          toast.error(error);
          setIsLoading(false);
          return error;
        });
      }
    }).catch(error => {
      console.log(error);
    });
  };

  return (
    <div className="container">
      <h1 className={style['title']}>Register your TeamRL account</h1>
      <div className={style['content']}>
        <p>In order to participate you need to have an active account with TeamRL. Registration
        is free and we'll never send unwanted emails or sell your data.</p>
      </div>
      <div className={style['form']}>
        <div>
          <label>Display Name</label>
          <input
            type="text"
            onChange={(e) => setDisplayName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Email Address</label>
          <input
            type="email"
            onChange={(e) => setEmailAddress(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password</label>
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <button
            aria-busy={isLoading}
            onClick={e => registerNewUser()}
          >
            Register new account
          </button>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
