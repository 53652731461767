import { useContext, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { UserContext } from 'Utils/UserContext';
import { createDiscussion } from 'API/createDiscussion';
import toast from 'react-hot-toast';

import teamList from 'API/teams.json';

import { TextEditor } from 'Components';

const CreateDiscussionPage = () => {
  const [title, setTitle] = useState('');
  const [tag, setTag] = useState('none');
  const [teamTag, setTeamTag] = useState(null);
  const [subMode, setSubMode] = useState(true);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const getEditorResponse = (data) => {
    submitDiscussion(data);
  };

  const submitDiscussion = (data) => {
    const replyData = {
      title: title,
      message: data,
      user: user.profile.displayName,
      sub: subMode,
      tag: tag,
      teamTag: teamTag,
      created: Date.now()
    };

    createDiscussion(replyData).then(response => {
      navigate(`/discuss/${response}`);
      toast.success('Added new discussion');
    }).catch(error => {
      console.log(error);
    });
  };

  return (user.profile.mod || user.profile.status === 'active')
  ?
    <div className="container">
      <h5>Create a new discussion</h5>
      <p>Before creating a new discussion please follow the guidelines:</p>
      <ul>
        <li>Discussions should be relevant and not a duplicate</li>
        <li>The title should be clear and accurate - no click bait</li>
        <li>You accept our <Link to="/aup">AUP</Link> and the discussion doesn't break any points</li>
      </ul>
      <label>Discussion title</label>
      <input type="text" onChange={(e) => setTitle(e.target.value)} />
      <label>Discussion tag</label>
      <select onChange={(e) => setTag(e.target.selectedOptions[0].text)}>
        <option value="none">None</option>
        <option value="signing">Signing</option>
        <option value="teamnews">Team news</option>
        <option value="player">Player</option>
        <option value="challengecup">Challenge cup</option>
        {user.profile.mod &&
          <option value="weeklyteam">Weekly team Discussion</option>
        }
      </select>
      {tag === 'Team news' &&
        <div>
          <label>Select Team</label>
          <select onChange={(e) => setTeamTag(e.target.selectedOptions[0].text)}>
            <option>Please select..</option>
            {teamList['super-league'].map((team, index) => (
              <option key={index} value={team.slug}>{team.name}</option>
            ))}
          </select>
        </div>
      }
      {user.profile.mod &&
        <div>
          <label>Show [Sub] in title (MOD ONLY)</label>
          <input type="checkbox" checked={subMode} onChange={(e) => setSubMode(e.target.checked)} />
        </div>
      }
      <TextEditor
        title="Discussion content"
        handleEditorResponse={getEditorResponse}
      />
    </div>
  : (
    <div>
      Sorry you're not authorised to create new discussions.
    </div>
  );
}

export default CreateDiscussionPage;
