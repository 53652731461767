export const CheckFixtureIsLive = (timestamp) => {
  const inPlay = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
  const currentTime = Date.now();

  const fixtureDate = new Date(timestamp);

  // Get unix timestamps for now and inPlay
  const fixtureDateTS = fixtureDate.getTime();
  const inPlayTS = fixtureDateTS + inPlay;

  if (currentTime >= fixtureDateTS && currentTime <= inPlayTS) {
    return true;
  }
};

export const checkPastFixture = (givenDate) => {
  const givenDateInMillis = new Date(givenDate).getTime();
  const currentTimeInMillis = Date.now();
  const timeDiffInHours = (currentTimeInMillis - givenDateInMillis) / (1000 * 60 * 60);

  if (timeDiffInHours >= 2) {
    return true;
  }
};
