import { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Helmet } from "react-helmet";
import { app } from 'Utils/Firebase';
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import toast from 'react-hot-toast';
import { UserContext } from 'Utils/UserContext';
import { DateFormat } from 'Utils/DateFormat';
import { replySingleDiscussion } from 'API/handleDiscussionReplies';
import { TextEditor, AdPanel } from 'Components';
import ThreadReplies from './replies';

import {
  FileText
} from 'react-feather';

import style from './thread.module.scss';

const DiscussThreadPage = (props) => {
  let { id } = useParams();
  const { user } = useContext(UserContext);
  const [thread, setThread] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const db = getFirestore(app);

  const getEditorResponse = (data) => {
    submitReply(data);
  };

  const checkSubStatus = () => {
    if (user.profile.status === 'active') {
      return true;
    }
    return false;
  };

  const submitReply = (data) => {
    const replyData = {
      'message': data,
      'user': user.profile.displayName,
      'sub': checkSubStatus(),
      'date': Date.now()
    };

    replySingleDiscussion(id, replyData).then(response => {
      toast.success(response);
    }).catch(error => {
      console.log(error);
    });
  };

  useEffect(() => {
    onSnapshot(doc(db, 'discussions', id), (doc) => {
      if (doc.data()) {
        setThread(doc.data());
        setIsLoading(false);
      }
    });
  }, [id, db]);

  return isLoading ? <div aria-busy="true"></div> : (
    <div className="container">
      <Helmet>
        <title>{`${thread.title} | TeamRL • Social Rugby League`}</title>
      </Helmet>
      <div className={style['header']}>
        <h3 className={style['title']}>
          <FileText className={style['icon']} size={18} />
          {thread.title}
        </h3>
        <div className={style['expire']}>
          Expires: {DateFormat(thread.created, true)}
        </div>
      </div>
      <div className={style['content']}>
        <div className={style['metadata']}>
          By:
          <span className={`${style['user']} ${thread.sub ? style['is-sub'] : null}`}>{thread.user}</span>
          » {DateFormat(thread.created)}
        </div>
        <ReactMarkdown>
          {thread.message}
        </ReactMarkdown>
      </div>
      <AdPanel />
      {thread.replies &&
        <ThreadReplies
          replies={thread.replies}
          threadID={id}
        />
      }

      {user.auth
        ?
          <TextEditor
            title="Reply"
            handleEditorResponse={getEditorResponse}
          />
        :
          <div className={style['no-auth']}>
            You need to be logged in or <Link to="/register">
              register
            </Link> to reply.
          </div>
      }
    </div>
  );
}

export default DiscussThreadPage;
