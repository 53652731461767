import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import {
  UserAccount
} from 'Components';

import style from './head.module.scss';
import Logo from './logo.svg';

const SiteHeader = () => {
  return (
    <Fragment>
      <div className={style['header']}>
        <Link className={style['site-logo']} to="/">
          <img src={Logo} alt="Team RL" />
        </Link>
      </div>
      <div className={style['nav']}>
        <UserAccount />
      </div>
    </Fragment>
  );
}

export default SiteHeader;
