import {
  DiscussionsList
} from 'Components';

import style from './style.module.scss';

const DiscussionsPage = () => {
  return (
    <div className="container">
      <div className={style['notice']}>
        Discussions are automatically removed after 14 days. All users can reply
        to discussions while subscribers can create new ones.
      </div>
      <DiscussionsList
        title="All Discussions"
        limit={100}
        filters={true}
        showAll={false}
      />
    </div>
  );
}

export default DiscussionsPage;
