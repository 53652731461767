import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { getNewsItem } from 'API/getNewsItem';

const NewsPage = () => {
  const [article, setArticle] = useState([]);
  let { slug } = useParams();

  useEffect(() => {
    getNewsItem(slug).then(response => {
      setArticle(response);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  return (
    <div className="container">
      <article>
        <header><strong>{article.title}</strong></header>
        <ReactMarkdown>
          {article.content}
        </ReactMarkdown>
      </article>
    </div>
  );
}

export default NewsPage;
