import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { MessageCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import { UserContext } from 'Utils/UserContext';
import { DateFormat } from 'Utils/DateFormat';
import { discussionTypes } from 'Utils/DiscussionTypes';

import { listDiscussions } from 'API/getDiscussions';

import style from './discuss.module.scss';

const DiscussionsList = (props) => {
  const { user } = useContext(UserContext);
  const [discussList, setDiscussList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const filterDiscussions = (option) => {
    const filtered = discussList.filter(a => a.tag === option);
    if (option) {
      return filtered;
    }

    return discussList;
  };

  useEffect(() => {
    listDiscussions(props.limit).then(response => {
      setDiscussList(response);
      setIsLoading(false);
    }).catch(error => {
      setIsLoading(false);
      console.log(error);
      toast.error(`Discussions failed to load: ${error.message}`);
    });
  }, [props.limit]);

  return isLoading ? <div aria-busy="true"></div> : (
    <div>
      <h5 className={style['title']}>
        {props.title}
        {(user.profile.mod || user.profile.status === 'active') &&
          <Link to='/create' className={style['add-btn']}>
            Create Discussion
          </Link>
        }
      </h5>

      {props.filters &&
        <div>
          Discussions 
        </div>
      }

      {/* {props.filters &&
        <div className={`grid ${style['filters']}`}>
          <label>Filter results</label>
          <select onChange={(e) => filterDiscussions(e.target.selectedOptions[0].text)}>
            {discussionTypes.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>
        </div>
      } */}

      <ul className={style['list']}>
        {filterDiscussions().map((item) => (
          <li key={item.id} className={style['list-item']}>
            <div>
              <h3 className={style['list-item-title']}>
                {item.sub &&
                  <span className={style['sub']}>[Sub]</span>
                }
                <Link to={`/discuss/${item.id}`}>
                  {item.title}
                </Link>
              </h3>
              <p className={style['list-item-desc']}>
                {(item.tag && item.tag !== 'none') &&
                  <span className={style['tag']}>{item.tag}</span>
                }
                {item.teamTag &&
                  <span className={`${style['tag']} ${style['tag--team']}`}>{item.teamTag}</span>
                }
                {DateFormat(item.created)}
              </p>
            </div>
            <div>
              <span className={style['list-item-date']}>
                <MessageCircle size={20} />
                {item.replies
                  ? item.replies.length
                  : '0'
                }
              </span>
            </div>
          </li>
        ))}
      </ul>
      {props.showAll &&
        <Link to='/discussions' className={style['btn']}>
          View all discussions
        </Link>
      }
    </div>
  );
}

export default DiscussionsList;
