import { app } from 'Utils/Firebase';
import { getFirestore, doc, deleteDoc } from "firebase/firestore/lite";

const db = getFirestore(app);

export const deleteFixture = async (id) => {
  await deleteDoc(doc(db, "matches", id));
  await deleteDoc(doc(db, "chat", id));

  return id;
};
