import { useContext } from 'react';
import { UserContext } from 'Utils/UserContext';

const useAuth = () => {
  const { user } = useContext(UserContext);
  return user.auth ? true : false;
}

const useCheckMod = () => {
  const { user } = useContext(UserContext);
  return user.profile.mod ? true : false;
}

const useCheckSub = () => {
  const { user } = useContext(UserContext);
  return user.profile.status === 'active' ? true : false;
}

export { useAuth, useCheckMod, useCheckSub };
