import { app } from 'Utils/Firebase';
import {
  getFirestore,
  doc,
  updateDoc,
  deleteField
} from "firebase/firestore";

const db = getFirestore(app);

export const deleteSingleChatItem = async (id, key) => {
  try {
    const chatDoc = doc(db, 'chat', id);
    await updateDoc(chatDoc, {
      [key]: deleteField()
    });
  } catch (e) {
    return e.message;
  }
};
